// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../assets/sass/abstracts/variables";

.loading {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
    min-height: 30px;

    &.preload {
        background: $white;
        position: fixed;
        z-index: 9999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    &.section-loader {
        position: absolute;
        height: auto;
        left: 0;
        bottom: 77px;
        z-index: 1;
    }

    .loading-dots {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        & > li {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: 3px;
            padding: 0;
            animation: loading-bouncedelay 1.4s infinite ease-in-out both;
            border-radius: 50%;
            background-color: $brand-primary;

            &:first-child {
                animation-delay: -0.32s;
            }

            &:nth-child(2) {
                animation-delay: -0.16s;
            }
        }
    }
}

@keyframes loading-bouncedelay {

    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
