// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../abstracts/variables";

// Pagination

$pagination-padding-y:              .75rem !default;
$pagination-padding-x:              1rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  #464040 !default;
$pagination-bg:                     #fff !default;
$pagination-border-width:           1px !default;
$pagination-border-color:           #ccc !default;

$pagination-focus-box-shadow:       #333 !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            #464040 !default;
$pagination-hover-bg:               #f1f1f1 !default;
$pagination-hover-border-color:     #ccc !default;

$pagination-active-color:           #fff !default;
$pagination-active-bg:              #c71c21 !default;
$pagination-active-border-color:    #c71c21 !default;

$pagination-disabled-color:         #464040 !default;
$pagination-disabled-bg:            #d7d7d7 !default;
$pagination-disabled-border-color:  #ccc !default;

$border-radius:                     4px !default;

.pagination {
	display: flex;
	margin: 0 auto;
	padding: 0;
	list-style: none;

	a {
		position: relative;
		display: block;
		padding: $pagination-padding-y * .6 $pagination-padding-x * .6;
		margin-left: -$pagination-border-width;
		line-height: $pagination-line-height;
		color: $pagination-color;
		background-color: $pagination-bg;
		border: $pagination-border-width solid $pagination-border-color;

		&:hover {
			z-index: 2;
			color: $pagination-hover-color;
			text-decoration: none;
			background-color: $pagination-hover-bg;
			border-color: $pagination-hover-border-color;
		}

		&:focus {
			z-index: 3;
			outline: $pagination-focus-outline;
			box-shadow: $pagination-focus-box-shadow;
		}
	}

	li {
		&:first-child {
			a {
				margin-left: 0;
				border-radius: $border-radius 0 0 $border-radius;
			}
		}
		&:last-child {
			a {
				border-radius: 0 $border-radius $border-radius 0;
			}
		}

		&.active a {
			z-index: 3;
			font-weight: 700;
			color: $pagination-active-color;
			background-color: $pagination-active-bg;
			border-color: $pagination-active-border-color;
		}

		&.disabled a {
			color: $pagination-disabled-color;
			pointer-events: none;
			// Opinionated: remove the "hand" cursor set previously for .page-link
			cursor: auto;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border-color;
		}
	}
}

/***************************************************/
//
// Media Queries
//
/***************************************************/

@media (min-width: 560px) {

	.pagination {

		a {
			padding: $pagination-padding-y $pagination-padding-x;
		}
	}
}
