//
// Colors
//
$gray-lightest:                     #f2f2f2;
$gray-lighter:                      #dfdfdf;
$gray-light:                        #d7d7d7;
$gray-dark:                         #95969a;
$gray-darker:                       #464040;
$red:                               #dd1f25;
$red-dark:                          #ab181d;
$black:                             #000;
$white:                             #fff;
$pink:                              #f66e7e;
$blue-light:                        #6eabf6;
$green:                             #62d586;
$green-alt:                         #aad7cd;
$yellow:                            #ffe15e;

$brand-primary:                     $red;
$brand-primary-dark:                $red-dark;

$error:                             $red-dark;
$default:                           $gray-darker;
$warning:                           $yellow;
$success:                           $green;

$default-radius:                    4px;

$border-radius:                     $default-radius;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:            'Lato', sans-serif;
$font-family-base:                  $font-family-sans-serif;
$font-size-base:                    16px;
$font-weight-base:                  400;
$line-height-base:                  1.5;
$body-color:                        $gray-darker;
$body-bg:                           $white;
$headings-margin-bottom:            1.2rem;
$paragraph-margin-bottom:           1.2rem;
$dt-font-weight:                    600;
$font-weight-bolder:                600;
$link-color:                        $brand-primary;
$link-hover-color:                  darken($link-color, 15%);
$link-decoration:                   none;
$link-hover-decoration:             underline;

$table-cell-padding:                10px;
$table-caption-color:               $gray-darker;
$label-margin-bottom:               0.3rem;
$label-font-weight:                 700;

$main-container-width:              1200px;

// Froms
//
// Define footer colors.
$input-color:                       $gray-darker;
$input-bg:                          $white;
$input-border-color:                $gray-light;
$input-disabled-bg:                 #ddd;
$input-focus-border-color:          $gray-darker;
$input-error-border-color:          $error;
$input-success-border-color:        $success;
$input-font-family:                 $font-family-sans-serif;

$btn-color:                         $gray-darker;
$btn-bg:                            #f1f1f1;
$btn-primary-color:                 $white;
$btn-primary-bg:                    $brand-primary;

// NavBar
//
// Define navbar colors.
$navbar-bg:                         $brand-primary;
$nav-link-color:                    $white;
$nav-link-hover-bg:                 $brand-primary-dark;
$nav-link-active-bg:                $brand-primary;

// SideBar
//
// Define sidebar colors.
$sidebar-bg:                        transparent;
$sidebar-link-color:                $gray-darker;
$sidebar-link-active-color:         $brand-primary;
$sidebar-link-active-bg:            transparent;

// Footer
//
// Define footer colors.
$footer-bg:                         #2f2a2a;
$footer-color:                      $white;

// Table
//
// Define footer colors.
$table-hover-bg:                    #fef3f4;
