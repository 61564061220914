/*!
Main styles for Edly Panel
*/
@import "./abstracts/variables";
@import "./abstracts/breakpoints";
@import "./abstracts/forms";
@import "./reboot";

h1 {
    font-size: 30px;
}

h3 {
    font-size: 1rem;
}

html, body,
.wrapper {
    height: 100%;
}

body {
    min-width: 320px;
}

iframe {
    width: 100%;
    display: block;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.main {
    padding: 25px 15px;

    .container {
        max-width: $main-container-width;
        margin: 0 auto;
    }
}

.ellipsis {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;

    &:focus {
        background-color: $gray-lighter;
        border-radius: $border-radius;
        box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
        clip: auto;
        clip-path: none;
        display: block;
        font-size: 14px;
        font-size: .875rem;
        font-weight: bold;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 5px 10px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000;
    }
}

/***************************************************/
//
// Media Queries
//
/***************************************************/

@media (max-width: 767px) {

    .hidden-mobile {
        display: none;
    }
}

@media (min-width: 768px) {

    .main {
        flex-grow: 1;

        .container {
            display: flex;
        }

        .main-content {
            flex-basis: 77.8%;
            max-width: 77.8%;
        }

        .sidebar {
            flex-basis: 22.2%;
            max-width: 22.2%;
        }
    }
}
